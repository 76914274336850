.map-partyland {
  background: black;
  width: 2400px;
  height: 2400px;
  overflow: scroll;
  margin: 0 auto;
}

.row {
  height: 5px;
  display: flex;
  margin-bottom: 1px;
}

.cell {
  display: inline-block;
  width: 5px;
  height: 5px;
  margin-right: 1px;
}

.cell:hover {
  background: white;
}

.cell:hover {
  opacity: 0.4;
}

.cell--green {
  background: green;
  opacity: 0.5;
}

.cell--blue {
  background: blue;
  opacity: 0.5;
}

.cell--yellow {
  background: yellow;
  opacity: 0.5;
}

.cell--grey {
  background: grey;
  opacity: 0.5;
}

.cell--orange {
  background: orange;
  opacity: 0.5;
}

.cell--extra-1 {
  background: green;
  opacity: 0.8;
}

.cell--extra-2 {
  background: green;
  opacity: 0.7;
}

.cell--extra-3 {
  background: green;
  opacity: 0.6;
}
